<script lang="ts" setup>
import { New } from '@/components';
import { AppTypography } from '@/components/base';
import {
  JobListingRequiredDocuments,
  JobListingRequiredSchedules,
  JobListingSupportPreference,
  JobListingPayRate,
} from '@/components/support-seeker';
import { ReportJobListing } from '@/components/support-seeker/forms';
import type { JobListing } from '@/types/JobListing';
import CONFIG from '@/utils/Config';

const props = withDefaults(
  defineProps<{
    jobListing?: JobListing;
    jobListingRequirements?: JobListing;
  }>(),
  {}
);

const price = computed((): string => {
  return `$${props.price || 0} ${CONFIG.currency.label} per hour`;
});
</script>
<template>
  <New class="mb-4" />
  <div class="d-flex justify-space-between mb-4">
    <div>
      <AppTypography type="title-2" class="mb-1">{{ jobListing.title }}</AppTypography>
      <AppTypography type="callout">{{ price }}</AppTypography>
    </div>
    <ReportJobListing />
  </div>

  <AppTypography type="subheadline-2" class="mb-4">{{ jobListing.description }}</AppTypography>

  <div class="mb-4">
    <AppTypography type="body-bold" class="mb-2">Required documents</AppTypography>
    <AppTypography type="callout" class="mb-3"
      >This job is requirement-sensitive. You will not be able to proceed if you don't have the following
      documents.</AppTypography
    >
    <JobListingRequiredDocuments :required-documents="jobListingRequirements?.required_docs_and_qualification" />
  </div>

  <div class="mb-5">
    <AppTypography type="body-bold" class="mb-3">Required hours</AppTypography>
    <JobListingRequiredSchedules
      :is-recurring="jobListingRequirements?.is_recurring"
      :recurring-schedules="jobListingRequirements?.recurring_schedules"
      :one-time-schedule="jobListingRequirements?.one_time_schedule"
    />
  </div>

  <div class="mb-5">
    <AppTypography type="body-bold" class="mb-3">Support preferences</AppTypography>
    <JobListingSupportPreference :preferences="jobListingRequirements?.support_preference_summary" />
  </div>

  <div class="mb-10">
    <AppTypography type="body-bold" class="mb-3">Pay rates offered</AppTypography>
    <JobListingPayRate :pay-rate="jobListingRequirements?.pay_rate" />
  </div>
</template>
<style scoped lang="scss">
.job-listing-details {
}
</style>
