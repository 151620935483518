<script lang="ts" setup>
import avatarPlaceholderImg from '@/assets/img/avatar-placeholder.svg';
import { RequirementsMet } from '@/components';
import { AppAvatar, AppButton, AppFormCard, AppTypography } from '@/components/base';
import { RequirementMetStatus } from '@/enums/RequirementMetStatus';
import dayjs from 'dayjs';

const props = withDefaults(
  defineProps<{
    avatar?: string;
    fullname?: string;
    birthdate?: string;
    description?: string;

    position?: string;
    employer?: string;

    requirementsMet?: RequirementMetStatus.NONE | RequirementMetStatus.SOME | RequirementMetStatus.ALL;
  }>(),
  {
    avatar: avatarPlaceholderImg,

    requirementsMet: RequirementMetStatus.NONE,
  }
);

const age = computed((): string => {
  return props.birthdate ? dayjs().diff(dayjs(props.birthdate), 'years') : 'NA';
});

const title = computed((): string => {
  return `${props.fullname}, ${age.value}`;
});
</script>
<template>
  <AppFormCard :no-shadow="false" :no-gutter="true" class="support-worker-card">
    <div class="d-flex mb-2">
      <AppAvatar size="59" :src="avatar" :rounded="100" class="mr-4" />
      <div>
        <AppTypography type="subheadline-1" class="mb-1"> {{ title }} </AppTypography>
        <AppTypography type="subheadline-2">
          {{ position }}
        </AppTypography>
        <AppTypography type="subheadline-2">
          {{ employer }}
        </AppTypography>
      </div>
    </div>

    <AppTypography type="body-2" class="support-worker-card__desc mb-4">
      {{ description }}
    </AppTypography>

    <RequirementsMet :status="requirementsMet" class="mb-4" />

    <AppButton size="small" block>Invite to apply</AppButton>
  </AppFormCard>
</template>
<style lang="scss" scoped>
@use '@/assets/scss/_mixins.scss';

.support-worker-card {
  &__desc {
    @include mixins.wrap-text-ellipsis(8);
  }
}
</style>
