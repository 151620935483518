<script lang="ts" setup>
import icCheckCircle from '@/assets/img/icon/ic-check-circle.svg';
import icCloseCircle from '@/assets/img/icon/ic-close-circle.svg';
import icClockCircle from '@/assets/img/icon/ic-clock-circle.svg';
import icCalendar from '@/assets/img/icon/ic-calendar.svg';
import { AppTypography } from '@/components/base';
import { ScheduleRequirements } from '@/components';
import { useAuthStore } from '@/modules/auth/store/auth';
import dayjs from 'dayjs';
import type { Availability } from '@/types/Availability';

const props = withDefaults(
  defineProps<{
    isRecurring?: boolean;

    recurringSchedules?: Availability[];
    oneTimeSchedule?: Availability;
  }>(),
  {
    isRecurring: false,

    recurringSchedules: () => [],
  }
);

const { user } = useAuthStore();

const startEndTime = computed((): string => {
  return props.oneTimeSchedule
    ? `${formatTime(props.oneTimeSchedule.start_time)} - ${formatTime(props.oneTimeSchedule.end_time)} weekdays`
    : '-';
});

const startEndDate = computed((): string => {
  return props.oneTimeSchedule
    ? `${formatDate(props.oneTimeSchedule.start_date)} - ${formatDate(props.oneTimeSchedule.end_date)}`
    : '-';
});

const formatTime = (date: string): string => {
  return dayjs(date, 'HH:mm').format('h:mma');
};

const formatDate = (date: string): string => {
  return dayjs(date).format('MM/DD/YYYY');
};
</script>
<template>
  <div class="job-listing-required-documents">
    <template v-if="props.isRecurring">
      <ScheduleRequirements :schedules="user?.availability_schedule" :required-schedules="recurringSchedules" />
    </template>
    <template v-else>
      <div>
        <div class="d-flex mb-2">
          <v-img :src="icClockCircle" max-width="18" height="18" class="mr-2" />
          <AppTypography type="subheadline-2">{{ startEndTime }}</AppTypography>
        </div>
        <div class="d-flex">
          <v-img :src="icCalendar" max-width="18" height="18" class="mr-2" />
          <AppTypography type="subheadline-2">{{ startEndDate }}</AppTypography>
        </div>
      </div>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.job-listing-required-schedule {
}
</style>
