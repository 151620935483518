import Model from '@/core/models/Model';
import dayjs from 'dayjs';

export default class SupportWorker extends Model {
  resource() {
    return 'users';
  }

  get profilePicture() {
    return this.avatar?.thumb_url || this.avatar_permanent_thumb_url;
  }

  get name() {
    return `${this.first_name} ${this.last_name}`.trim();
  }

  get age() {
    return dayjs().diff(this.birthdate, 'year');
  }

  get currentJob() {
    if (!this.work_experiences?.length) return '';

    const current = this.work_experiences.filter((a) => a.is_current);
    return current ? current[0] : '';
  }

  get currentPosition() {
    return this.currentJob ? this.currentJob.position : '';
  }

  get currentEmployer() {
    return this.currentJob ? this.currentJob.employer : '';
  }
}
