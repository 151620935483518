<script lang="ts" setup>
import icRate from '@/assets/img/icon/ic-rate.svg';
import { AppTypography } from '@/components/base';
import CONFIG from '@/utils/Config';

const props = withDefaults(
  defineProps<{
    isRecurring?: boolean;

    payRate?: {
      hourly: string | number;
      weekday: string | number;
      weekday_night: string | number;
      weekend: string | number;
      weekend_night: string | number;
    };
  }>(),
  {
    isRecurring: false,
  }
);

const prices = computed((): string[] => {
  return props.isRecurring
    ? [
        format(props.payRate?.weekday, 'Weekday'),
        format(props.payRate?.weekday_night, 'Weekday Night'),
        format(props.payRate?.weekend, 'Weekend'),
        format(props.payRate?.weekend_night, 'Weekend Night'),
      ]
    : [format(props.payRate?.hourly)];
});

const format = (price: number, append: string): void => {
  return `$${price || 0} ${CONFIG.currency.label} per hour ${append ? `- ${append}` : ''}`;
};
</script>
<template>
  <div class="job-listing-pay-rates">
    <div v-for="(price, index) in prices" :key="index" class="job-listing-pay-rates__item d-flex">
      <v-img :src="icRate" max-width="18" height="18" class="mr-2" />
      <AppTypography type="subheadline-2">{{ price }}</AppTypography>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.job-listing-pay-rates {
  &__item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
