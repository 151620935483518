<script lang="ts" setup>
import icCheckCircle from '@/assets/img/icon/ic-check-circle.svg';
import icCloseCircle from '@/assets/img/icon/ic-close-circle.svg';
import { AppTypography } from '@/components/base';
import type { JobListingRequiredDocument } from '@/types/JobListing';

const props = withDefaults(
  defineProps<{
    requiredDocuments?: JobListingRequiredDocument[];
  }>(),
  {
    requiredDocuments: () => [],
  }
);

const requirements = computed(
  (): {
    label: string;
    icon: string;
  } => {
    return props.requiredDocuments.map((doc) => {
      return {
        label: doc.label,
        icon: !!doc.matched ? icCheckCircle : icCloseCircle,
      };
    });
  }
);
</script>
<template>
  <div class="job-listing-required-documents">
    <div v-for="(doc, index) in requirements" :key="index" class="job-listing-required-documents__item d-flex">
      <v-img :src="doc.icon" max-width="18" alt="icon" class="mr-2" />
      <AppTypography type="">{{ doc.label }}</AppTypography>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.job-listing-required-documents {
  &__item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
