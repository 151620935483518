<script lang="ts" setup>
import { AppTypography, AppButton, AppConfirmDialog } from '@/components/base';
import { JobListingDetails } from '@/components/support-seeker';
import { JobListingUploadDocuments } from '@/components/support-seeker/forms';
import type { JobListing } from '@/types/JobListing';

const props = withDefaults(
  defineProps<{
    jobListing?: JobListing;
  }>(),
  {}
);

const modal = defineModel<boolean>();
const showConfirm = ref(false);
const showSuccess = ref(false);
const isApplying = ref(false);
const isUploading = ref(false);
const { showSnackbar } = useSnackbar();
const { fetching, jobListing: jobListingRequirements, findJobListing } = useJobListing();

const hasRequirements = computed((): boolean => {
  return !!jobListingRequirements.value;
});

const hasAllDocuments = computed((): number => {
  const count = jobListingRequirements.value?.required_docs_and_qualification || 0;

  return (
    (jobListingRequirements.value?.required_docs_and_qualification || []).filter((doc) => !!doc.current_document)
      .length || 0 >= count
  );
});

const hasMatchingDocuments = computed((): number => {
  const count = jobListingRequirements.value?.required_docs_and_qualification || 0;

  return (
    (jobListingRequirements.value?.required_docs_and_qualification || []).filter(
      (doc) => !!doc.current_document?.approved_at
    ).length || 0 >= count
  );
});

watch(
  () => modal.value,
  (value) => {
    if (value) {
      reset();
    }

    if (!!value && !!props.jobListing?.id) {
      fetch();
    }
  }
);

const submit = (): void => {
  if (hasMatchingDocuments.value) {
    handleSubmit();
  } else {
    toggleConfirm(true);
  }
};

const handleSubmit = (): Promise<void> => {
  // TODO

  cancel();
  reset();

  toggleSuccess(true);
};

const fetch = async (): Promise<void> => {
  await findJobListing(props.jobListing?.id);

  if (!jobListingRequirements.value) {
    cancel();
  }
};

const toggleApplying = (bool: boolean): void => {
  isApplying.value = bool;
};

const toggleUploading = (bool: boolean): void => {
  isUploading.value = bool;
};

const toggleConfirm = (bool: boolean): void => {
  showConfirm.value = bool;
};

const toggleSuccess = (bool: boolean): void => {
  showSuccess.value = bool;
};

const cancel = (): void => {
  jobListingRequirements.value = null;
  modal.value = false;
};

const reset = (): void => {
  toggleApplying(false);
  toggleUploading(false);
  toggleConfirm(false);
};

onMounted(() => {
  reset();
});
</script>
<template>
  <v-dialog v-model="modal" :max-width="600">
    <v-card>
      <v-card-text>
        <template v-if="!isApplying">
          <template v-if="!fetching">
            <JobListingDetails :job-listing="jobListing" :job-listing-requirements="jobListingRequirements" />
            <AppButton :disabled="!hasRequirements" variant="tonal" block class="mb-4" @click="toggleApplying(true)"
              >Apply for job</AppButton
            >
          </template>
          <template v-else>
            <v-skeleton-loader type="article" />
          </template>

          <AppButton variant="text" block @click="cancel">Cancel</AppButton>
        </template>
        <template v-else>
          <JobListingUploadDocuments
            :required-documents="jobListingRequirements?.required_docs_and_qualification"
            class="pt-5"
            @cancel="toggleApplying(false)"
            @uploaded="fetch"
            @uploading="toggleUploading($event)"
          />

          <template v-if="!isUploading">
            <AppButton :disabled="!hasAllDocuments" block class="mb-4" @click="submit">Continue</AppButton>
            <AppButton variant="text" block @click="toggleApplying(false)">Cancel</AppButton>
          </template>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>

  <AppConfirmDialog
    v-model="showConfirm"
    title="Documents need to be verified"
    description="Your documents are subject to verification by SupportApp. If any of your documents are rejected, your application will also be rejected. Make sure you're submitting the right documents."
    confirm-text="Confirm"
    @confirm="handleSubmit"
    @cancel="toggleConfirm(false)"
  />

  <AppConfirmDialog
    v-model="showSuccess"
    title=""
    description=""
    confirm-text="Continue"
    cancel-text=""
    @confirm="toggleSuccess(false)"
  >
    <template #description>
      <AppTypography type="headline-1">You have successfully applied for this job!</AppTypography>
    </template>
  </AppConfirmDialog>
</template>
<style lang="scss" scoped></style>
