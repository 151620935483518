<script lang="ts" setup>
import completeYourProfileImg from '@/assets/img/complete-your-profile.svg';
import { AppButton, AppTypography, AppFormCard, AppProgressBar } from '@/components/base/';
import { useAuthStore } from '@/modules/auth/store/auth';

const router = useRouter();
const { user, verificationItems, verificationPercentage, isFullyVerified } = useAuthStore();

const complete = (): void => {
  router.push({
    name: 'profile',
  });
};
</script>
<template>
  <AppFormCard v-if="!isFullyVerified" class="complete-your-profile" :no-shadow="false" :no-gutter="true">
    <AppTypography type="headline-1" class="mb-1">Complete Your Profile</AppTypography>
    <AppTypography type="subheadline-2">Complete your profile to send requests to support seekers.</AppTypography>
    <AppTypography type="subheadline-2" class="mb-4"
      >We're ready to match you with support seekers in your area.</AppTypography
    >

    <AppProgressBar :percentage="verificationPercentage" class="mb-4" />

    <v-img width="179" height="179" min-width="179" :src="completeYourProfileImg" class="complete-your-profile__img" />

    <AppButton size="small" block @click="complete">Continue Account Setup</AppButton>
  </AppFormCard>
</template>
<style lang="scss" scoped>
.complete-your-profile {
  &__img {
    margin: 0 auto;
  }
}
</style>
