import jobListingService from '@/services/JobListingService';
import type JobListing from '@/types/JobListing';
import type PaginatedListMeta from '@/types/PaginatedListMeta';
import { API_LIST_META } from '@/utils/fixtures/ApiListMeta';

export default function useJobListing() {
  const fetching = ref(false);
  const jobListing = ref<JobListing | null>(null);
  const jobListings = ref<JobListing[]>([]);
  const jobListingsMeta = ref<PaginatedListMeta>(API_LIST_META);

  const getJobListings = async (page: number = 1, limit: number = 10, recommended?: boolean = false): JobListing[] => {
    if (fetching.value) return;

    jobListings.value = [];
    fetching.value = true;
    jobListingsMeta.value = API_LIST_META;

    const payload = {
      page: page,
      per_page: limit,
    };

    const { data } = recommended
      ? await jobListingService.getRecommendedJobListings(payload)
      : await jobListingService.getJobListings(payload);

    jobListings.value = data.value.data || [];
    jobListingsMeta.value = data.value.meta || API_LIST_META;
    fetching.value = false;

    return jobListings.value;
  };

  const findJobListing = async (id: number): JobListing | null => {
    if (fetching.value) return;

    jobListing.value = null;
    fetching.value = true;

    const { data } = await jobListingService.findJobListing(id);

    fetching.value = false;
    jobListing.value = data.value || null;

    return jobListing.value;
  };

  return {
    fetching,

    jobListing,
    jobListings,
    jobListingsMeta,

    getJobListings,
    findJobListing,
  };
}
