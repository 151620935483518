<script lang="ts" setup>
import { AppTypography } from '@/components/base';

const props = withDefaults(
  defineProps<{
    percentage?: number;

    barColor?: string;
    bgColor?: string;
    height?: number;

    showPercentage?: boolean;
  }>(),
  {
    percentage: 0,

    barColor: '#E1D1FB',
    bgColor: '#E2E2E2',
    height: 12,

    showPercentage: true,
  }
);

const percentageLabel = computed((): string => {
  return `${props.percentage || 0}%`;
});
</script>
<template>
  <div
    :style="{
      color: barColor,
    }"
    class="app-progress-bar d-flex align-center"
  >
    <v-progress-linear :model-value="percentage" :bg-color="bgColor" :height="height" />
    <AppTypography v-if="showPercentage" type="caption-2" class="app-progress-bar__label ml-2">{{
      percentageLabel
    }}</AppTypography>
  </div>
</template>
<style lang="scss" scoped>
.app-progress-bar {
  &__label {
    color: #07060a;
  }

  :deep(.v-progress-linear) {
    $borderRadius: 100px;
    border-radius: $borderRadius;

    .v-progress-linear__background {
      opacity: 1;
    }

    .v-progress-linear__determinate {
      border-radius: $borderRadius;
    }
  }
}
</style>
