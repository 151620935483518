<script lang="ts" setup>
import icReport from '@/assets/img/icon/ic-user-report.svg';
import { AppButton } from '@/components/base';

const props = withDefaults(
  defineProps<{
    jobListing?: JobListing;
  }>(),
  {}
);

const { showSnackbar } = useSnackbar();

const report = (): void => {
  showSnackbar({
    text: 'WIP',
    state: 'warning',
  });
};
</script>
<template>
  <AppButton color="color" variant="flat" icon="mdi-close" class="report-job-listing" @click="report">
    <v-img size="small" width="40" height="40" :src="icReport" />
  </AppButton>
</template>
<style lang="scss" scoped>
.report-job-listing {
  max-width: 32px;
}
</style>
