<script lang="ts" setup>
import icCloseDark from '@/assets/img/icon/ic-close-dark.svg';
import icDocumentFilled from '@/assets/img/icon/ic-document-filled.svg';
import { AppButton, AppTypography } from '@/components/base';
import {
  AustralianBusinessNumber,
  CovidVaccination,
  FirstAidCPR,
  IDDocument,
  NDISModule,
  PoliceCheck,
  PublicLiability,
  WorkingWithChildren,
} from '@/modules/auth/components/support-worker';
import QualificationForm from '@/modules/auth/components/support-worker/qualifications/QualificationForm.vue';
import { useAuthStore } from '@/modules/auth/store/auth';
import type { JobListingRequiredDocument } from '@/types/JobListing';
import { find } from 'lodash';

const props = withDefaults(
  defineProps<{
    requiredDocuments?: JobListingRequiredDocument[];
  }>(),
  {
    requiredDocuments: () => [],
  }
);

const emits = defineEmits<{
  (e: 'uploading', value: boolean): void;
  (e: 'uploaded'): void;
  (e: 'continue'): void;
}>();

const { user } = useAuthStore();
const selectedDocument = ref();
const requiredDocumentFormRef = ref();
const qualificationFormRef = ref();
const qualificationForm = ref({
  qualification_id: '',
  issued_at: '',
  media: '',
  description: '',
  attachment: '',
  enabled: true,
});
const { showSnackbar } = useSnackbar();
const { creating: savingQualification, createTrainingQualifications } = useTrainingQualification();

const documentCount = computed((): number => {
  return props.requiredDocuments?.length || 0;
});

const uploadedDocumentCount = computed((): number => {
  return (props.requiredDocuments || []).filter((doc) => !!doc.current_document).length || 0;
});

const uploadedLabel = computed((): string => {
  return `${uploadedDocumentCount.value}/${documentCount.value} document${documentCount.value > 0 ? 's' : ''} uploaded`;
});

const requiredDocumentTitle = computed((): string => {
  return !!selectedDocument.value ? `Upload document for ${selectedDocument.value.label}` : '';
});

const requiredDocumentForm = computed(() => {
  if (!selectedDocument.value || selectedDocument.value.type !== 'required_documents') return;

  switch (selectedDocument.value.value) {
    case 'id_documents':
      return IDDocument;
    case 'police_checks':
      return PoliceCheck;
    case 'abn':
      return AustralianBusinessNumber;
    case 'wwc':
      return WorkingWithChildren;
    case 'first_aid':
      return FirstAidCPR;
    case 'public_liability':
      return PublicLiability;
    case 'ndis_orientation_module':
      return NDISModule;
    case 'covid_vaccination':
      return CovidVaccination;
  }
});

const submitRequiredDocument = async (): Promise<void> => {
  if (requiredDocumentFormRef.value) {
    await requiredDocumentFormRef.value.submit();
  }
};

const submitQualification = async (): Promise<void> => {
  if (qualificationFormRef.value && (await qualificationFormRef.value.validate())) {
    const response = await createTrainingQualifications(getQualificationFormData());
    if (response) {
      onUpload();
      setSelectedDocument(null);
    } else {
      showSnackbar({
        text: 'There seems to be missing details, please check before proceeding',
        state: 'warning',
      });
    }
  }
};

const onUpload = (): void => {
  emits('uploaded');
};

const proceed = (): void => {
  emits('continue');
};

const setSelectedDocument = (doc: JobListingRequiredDocument | null): void => {
  if (doc?.type === 'qualifications') {
    const currentDocument = doc?.current_document?.data;

    if (!!currentDocument) {
      qualificationForm.value.qualification_id = currentDocument.qualification_id;
      qualificationForm.value.issued_at = currentDocument.issued_at;
      qualificationForm.value.description = currentDocument.description;
      qualificationForm.value.media = currentDocument.media?.id;
      qualificationForm.value.attachment = currentDocument.attachment;
    }
  }

  selectedDocument.value = doc;

  if (!!selectedDocument.value) {
    emits('uploading', true);
  } else {
    emits('uploading', false);
  }
};

/**
 * NOTE: Had to re-send the whole qualifications data the user currently have
 * as the create EP removes it if not passed too
 **/
const getQualificationFormData = () => {
  const formData = JSON.parse(JSON.stringify(user.qualifications || []));

  const exist = find(formData, { qualification_id: qualificationForm.value.qualification_id });
  if (exist) {
    exist.issued_at = qualificationForm.value.issued_at;
    exist.approved_at = qualificationForm.value.approved_at;
    exist.description = qualificationForm.value.description;
    exist.media = qualificationForm.value.media;
    exist.attachment = qualificationForm.value.attachment;
  } else {
    formData.push(qualificationForm.value);
  }

  return formData.filter((a) => !!a.issued_at);
};
</script>
<template>
  <div class="job-listing-upload-documents">
    <template v-if="!selectedDocument">
      <div class="mb-4">
        <AppTypography type="title-2" class="mb-1">Upload Documents</AppTypography>
        <AppTypography type="callout"
          >The seeker is requiring you to upload the following {{ documentCount }} document{{
            documentCount > 0 ? 's' : ''
          }}:</AppTypography
        >
      </div>

      <div class="job-listing-upload-documents__list mb-10">
        <div
          v-for="(doc, index) in requiredDocuments"
          :key="index"
          class="job-listing-upload-documents__list__item d-flex align-center justify-space-between"
        >
          <div class="d-flex align-center">
            <v-img :src="icDocumentFilled" max-width="40" width="40" class="mr-3" />
            <template v-if="!!doc.current_document?.data">
              <AppTypography type="callout">{{
                doc.current_document.data?.media?.file_name || 'File name not found'
              }}</AppTypography>
            </template>
            <template v-else>
              <AppTypography type="callout">Upload document for {{ doc.label }}</AppTypography>
            </template>
          </div>

          <template v-if="!!doc.current_document?.data">
            <v-img
              :src="icCloseDark"
              max-width="20"
              width="20"
              class="job-listing-upload-documents__list__item__close cursor-pointer"
              @click="setSelectedDocument(doc)"
            />
          </template>
          <template v-else>
            <AppButton size="small" color="#E3E3E3" @click="setSelectedDocument(doc)">
              <v-icon size="30" icon="mdi-chevron-right" color="black" />
            </AppButton>
          </template>
        </div>

        <AppTypography type="footnote-2" class="job-listing-upload-documents__list__note mb-1">{{
          uploadedLabel
        }}</AppTypography>
      </div>
    </template>
    <template v-else>
      <AppTypography type="title-2" class="mb-1">{{ requiredDocumentTitle }}</AppTypography>

      <template v-if="selectedDocument.type === 'required_documents'">
        <component
          ref="requiredDocumentFormRef"
          title=""
          :is="requiredDocumentForm"
          :is-onboarding="false"
          class="job-listing-upload-documents__required-document mb-6"
          @success="onUpload"
        />

        <AppButton
          block
          :loading="requiredDocumentFormRef?.pending"
          :disabled="requiredDocumentFormRef?.isDisabled || requiredDocumentFormRef?.pending"
          class="mb-4"
          @click="submitRequiredDocument"
          >Upload</AppButton
        >
      </template>
      <template v-else>
        <QualificationForm
          ref="qualificationFormRef"
          v-model="qualificationForm"
          :form="qualificationForm"
          :rule="selectedDocument.reference"
          :disabled="savingQualification"
          class="job-listing-upload-documents__qualifications"
        />

        <AppButton
          block
          :loading="savingQualification"
          :disabled="savingQualification"
          class="mb-4"
          @click="submitQualification"
          >Upload</AppButton
        >
      </template>

      <AppButton variant="text" block @click="setSelectedDocument(null)">Go back</AppButton>
    </template>
  </div>
</template>
<style lang="scss" scoped>
@use '~/assets/scss/fonts';

.job-listing-upload-documents {
  &__list {
    &__item {
      padding: 15px 10px;
      border-radius: 8px;
      border: 1px solid #e6e6e7;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .v-btn {
        $size: 40px;
        width: $size;
        height: $size;
        border-radius: 8px !important;

        :deep(.v-btn__overlay) {
          opacity: 1;
        }
      }

      &__close {
      }
    }

    &__note {
      text-align: right;
    }
  }

  &__required-document {
    > :deep(.app-form-header) {
      display: none !important;
    }

    > :deep(.v-form) {
      .v-card {
        padding: 0 !important;
        border: none;
      }

      > .v-card {
        > div:nth-child(2) {
          .v-card-title {
            display: none;
          }
        }
      }
    }
  }

  &__qualifications {
    :deep(.v-card-title) {
      span,
      .v-input {
        display: none;
      }
    }

    :deep(.v-card-text) {
      padding: 0 !important;
    }
  }
}
</style>
