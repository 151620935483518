<script lang="ts" setup>
import icFilter from '@/assets/img/icon/ic-filter.png';
import { AppButton, AppTypography } from '@/components/base';
import { ComplateProfileDialog } from '@/components';
import { JobListingApplicationForm } from '@/components/support-seeker/forms';
import { useAuthStore } from '@/modules/auth/store/auth';
import { JobListingCard } from '@/components/support-seeker';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import type JobListing from '@/types/JobListing';

const props = withDefaults(
  defineProps<{
    title?: string;
    subtitle?: string;
    parentClass?: string;

    carousel?: boolean;
    limit?: number;

    recommended?: boolean;
  }>(),
  {
    title: 'Job listings',
    subtitle: 'Job listings were recently posted',

    carousel: false,
    limit: 10,

    recommended: false,
  }
);

const page = ref(1);
const showVerification = ref(false);
const showViewing = ref(false);
const selectedJob = ref<JobListing | null>(null);
const { isFullyVerified } = useAuthStore();
const { jobListings, jobListingsMeta, fetching, getJobListings } = useJobListing();

const fetch = async (newPage: number = 1): Promise<void> => {
  page.value = newPage;
  await getJobListings(page.value, props.limit, props.recommended);
};

const viewJob = (job: JobListing | null): void => {
  selectedJob.value = job;

  toggleViewing(!!selectedJob.value);
};

const toggleViewing = (bool: boolean): void => {
  showViewing.value = bool;
};

const toggleViewAll = (): void => {
  if (!isFullyVerified) {
    toggleVerification(true);
  }
};

const toggleVerification = (bool: boolean = null): void => {
  showVerification.value = bool === null ? !showVerification.value : bool;
};

onMounted(() => {
  nextTick(() => {
    if (isFullyVerified) {
      fetch();
    }
  });
});
</script>
<template>
  <div :class="`job-list ${parentClass} ${carousel ? 'job-list--carousel' : ''}`">
    <div class="job-list__header d-flex justify-space-between mb-6">
      <div>
        <AppTypography type="headline-1" class="mb-1">{{ title }}</AppTypography>
        <AppTypography type="subheadline-2">{{ subtitle }}</AppTypography>
      </div>

      <AppButton variant="text" size="small" color="primary" class="job-list__view" @click="toggleViewAll">
        View all
        <v-icon icon="mdi-chevron-right" class="ml-1" />
      </AppButton>
    </div>
    <div class="job-list__list">
      <template v-if="!fetching">
        <template v-if="jobListings.length">
          <template v-if="!carousel">
            <v-row no-gutter>
              <v-col v-for="(job, index) in jobListings" :index="index" cols="12" md="4">
                <JobListingCard
                  :id="job.id"
                  :title="job.title"
                  :description="job.description"
                  :price="job.pay_rate_hourly"
                  class="job-list__list__item"
                  @view="viewJob(job)"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <Carousel
              :settings="{
                itemsToShow: 3.5,
                snapAlign: 'start',
              }"
              :breakpoints="{
                600: {
                  itemsToShow: 1,
                  snapAlign: 'start',
                },
                960: {
                  itemsToShow: 2.5,
                  snapAlign: 'start',
                },
              }"
              class="job-list__carousel"
            >
              <Slide v-for="(job, index) in jobListings" :index="index" class="job-list__carousel__item">
                <JobListingCard
                  :id="job.id"
                  :title="job.title"
                  :description="job.description"
                  :price="job.pay_rate_hourly"
                  class="job-list__list__item"
                  @view="viewJob(job)"
                />
              </Slide>
            </Carousel>
          </template>
        </template>
        <template v-else>
          <AppTypography type="headline-2" class="text-center my-4">No job listing found</AppTypography>
        </template>
      </template>
      <template v-else>
        <v-row no-gutter>
          <v-col v-for="n in 3" :index="n" cols="12" md="4">
            <div class="job-list__list__loader">
              <v-skeleton-loader type="list-item-three-line" />
              <v-skeleton-loader type="heading" />
            </div>
          </v-col>
        </v-row>
      </template>
    </div>
  </div>

  <ComplateProfileDialog v-model="showVerification" />
  <JobListingApplicationForm v-model="showViewing" :job-listing="selectedJob" />
</template>
<style lang="scss" scoped>
.job-list {
  &__view {
  }

  &__list {
    &__loader {
      border-radius: 16px;
      box-shadow: 0px 8px 9px 0px #0000001a;
      overflow: hidden;
    }
  }
}

.job-list--carousel {
  :deep(.carousel) {
    .carousel__viewport {
      padding: 0 5px;
    }

    .carousel__slide {
      max-width: 345px;
      margin-top: 10px;
      margin-bottom: 15px;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .job-list__list {
    &__item {
    }
  }

  @media (max-width: 600px) {
    :deep(.carousel) {
      .carousel__viewport {
        overflow: visible;
      }

      .carousel__slide {
        max-width: 100%;
      }
    }
  }
}
</style>
