<script lang="ts" setup>
import { AppButton, AppFormCard, AppTypography } from '@/components/base';
import CONFIG from '@/utils/Config';

const props = withDefaults(
  defineProps<{
    id: number | string;
    title: string;
    description: string;

    price?: string | number | null;
  }>(),
  {
    price: 0,
  }
);

const emits = defineEmits<{
  (e: 'view'): void;
}>();

const price = computed((): string => {
  return `$${props.price || 0} ${CONFIG.currency.label}`;
});

const view = (): void => {
  emits('view');
};
</script>
<template>
  <AppFormCard :no-shadow="false" :no-gutter="true" class="job-listing-card">
    <div class="job-listing-card__border text-left mb-4">
      <AppTypography type="headline-1" class="job-listing-card__border__title mb-2">{{ title }}</AppTypography>
      <AppTypography type="subheadline-2" class="job-listing-card__border__desc">{{ description }}</AppTypography>
    </div>

    <div class="d-flex justify-space-between align-start">
      <div class="text-left">
        <AppTypography type="subheadline-1" class="mb-1">{{ price }}</AppTypography>
        <AppTypography type="footnote-2">per hour</AppTypography>
      </div>
      <AppButton size="small" @click="view">View listing</AppButton>
    </div>
  </AppFormCard>
</template>
<style lang="scss" scoped>
@use '@/assets/scss/_mixins.scss';

.job-listing-card {
  width: 100%;
  height: 100%;

  &__border {
    padding: 20px;
    background: #f3f6ff;
    border-radius: 12px;
    flex-grow: 1;

    &__title {
      @include mixins.wrap-text-ellipsis(1);
    }

    &__desc {
      @include mixins.wrap-text-ellipsis(2);
    }
  }

  :deep(.v-card-text) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
