<script lang="ts" setup>
import icCheckAll from '@/assets/img/icon/check-circle-met.svg';
import icCheckNone from '@/assets/img/icon/check-circle-notmet.svg';
import icCheckSome from '@/assets/img/icon/check-circle-some.svg';
import { AppTypography } from '@/components/base';
import { RequirementMetStatus } from '@/enums/RequirementMetStatus';
import { capitalize } from '@/utils/Helpers';

const props = withDefaults(
  defineProps<{
    status: RequirementMetStatus.NONE | RequirementMetStatus.SOME | RequirementMetStatus.ALL;
  }>(),
  {}
);

const icon = computed(() => {
  const icons = {
    none: icCheckNone,
    some: icCheckSome,
    all: icCheckAll,
  };

  return icons[props.status];
});
</script>
<template>
  <v-sheet class="requirements-met d-flex">
    <img :src="icon" size="34" alt="icon" />
    <AppTypography type="footnote-2" class="ml-2 pt-1">
      Specified Requirements Met ({{ capitalize(status) }})
    </AppTypography>
  </v-sheet>
</template>
<style lang="scss" scoped>
.requirements-met {
}
</style>
