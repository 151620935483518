<script lang="ts" setup>
import icFilter from '@/assets/img/icon/ic-filter.png';
import { AppButton, AppTypography } from '@/components/base';
import { ComplateProfileDialog } from '@/components';
import { NoListingCard } from '@/components/support-seeker';
import { useAuthStore } from '@/modules/auth/store/auth';
import { SupportWorkerCard } from '@/components/support-worker';

const props = withDefaults(
  defineProps<{
    title?: string;
    subtitle?: string;

    limit?: number;

    recommended?: boolean;
  }>(),
  {
    title: 'Browse workers',
    subtitle: 'You can invite them to apply',

    limit: 12,

    recommended: false,
  }
);

const page = ref(1);
const showVerification = ref(false);
const { isFullyVerified } = useAuthStore();
const { supportWorkers, supportWorkersMeta, fetching, getSupportWorkers } = useSupportWorker();

const fetch = async (newPage: number = 1): Promise<void> => {
  page.value = newPage;
  await getSupportWorkers(page.value, props.limit, props.recommended);
};

const loadMore = (): void => {
  const nextPage = page.value + 1;
  if (nextPage <= supportWorkersMeta.value.last_page) {
    fetch(nextPage);
  }
};

const toggleFilter = (): void => {
  if (!isFullyVerified.value) {
    toggleVerification(true);
  }
};

const toggleVerification = (bool: boolean = null): void => {
  showVerification.value = bool === null ? !showVerification.value : bool;
};

onMounted(() => {
  nextTick(() => {
    if (isFullyVerified) {
      fetch();
    }
  });
});
</script>
<template>
  <div class="support-worker-list">
    <div class="support-worker-list__header d-flex justify-space-between mb-6">
      <div>
        <AppTypography type="headline-1" class="mb-1">{{ title }}</AppTypography>
        <AppTypography type="subheadline-2">{{ subtitle }}</AppTypography>
      </div>

      <AppButton size="small" color="#000" class="support-worker-list__filter" @click="toggleFilter">
        <v-img width="24" height="24" :src="icFilter" />
      </AppButton>
    </div>
    <div class="support-worker-list__list">
      <NoListingCard v-if="!supportWorkers.length && !fetching" />
      <v-row v-if="supportWorkers.length" no-gutter>
        <v-col v-for="(supportWorker, index) in supportWorkers" :index="index" cols="12" md="4">
          <SupportWorkerCard
            :avatar="
              supportWorker.avatar?.thumb_url ||
              supportWorker.avatar_permanent_thumb_url ||
              supportWorker.avatar?.url ||
              supportWorker.avatar_permanent_url
            "
            :fullname="
              supportWorker.first_name && supportWorker.last_name
                ? `${supportWorker.first_name} ${supportWorker.last_name}`
                : '-'
            "
            :birthdate="supportWorker.birthdate"
            :position="supportWorker.currentPosition"
            :employer="supportWorker.currentEmployer"
            :description="supportWorker.description"
            :requirements-met="supportWorker.requirements_met"
          />
        </v-col>
      </v-row>

      <template v-if="fetching">
        <v-row no-gutter>
          <v-col v-for="n in 3" :index="n" cols="12" md="4">
            <div class="support-worker-list__list__loader">
              <v-skeleton-loader type="list-item-avatar" />
              <v-skeleton-loader type="list-item-three-line" />
              <v-skeleton-loader type="heading" />
            </div>
          </v-col>
        </v-row>
      </template>

      <div v-intersect="loadMore" />
    </div>
  </div>

  <ComplateProfileDialog v-model="showVerification" />
</template>
<style lang="scss" scoped>
.support-worker-list {
  &__filter {
    width: 40px;
    height: 40px;
    min-width: inherit;
    border-radius: 8px !important;
  }

  &__list {
    &__loader {
      border-radius: 16px;
      box-shadow: 0px 8px 9px 0px #0000001a;
      overflow: hidden;
    }
  }
}
</style>
