import SupportWorker from '@/models/SupportWorker';
import type { SupportWorker as ISupportWorker, SupportWorkerFilter } from '@/types/SupportWorker';

class SupportWorkerService {
  getSupportWorkers(options?: SupportWorkerFilter) {
    return useAsyncData<ISupportWorker[]>(
      'support-workers',
      async () => {
        const { page, filter, includes, per_page, sort }: SupportWorkerFilter = options || {
          page: 1,
          per_page: 10,
          filter: {},
          includes: ['avatar'],
          sort: '-created_at',
        };

        const supportWorker = new SupportWorker();
        supportWorker.include(includes).limit(per_page).page(page).orderBy(sort);

        const supportWorkers = await supportWorker.custom('users').get();
        return { data: supportWorkers, meta: supportWorker.meta };
      },
      { server: false }
    );
  }

  getRecommendedSupportWorkers(options?: SupportWorkerFilter) {
    return useAsyncData<ISupportWorker[]>(
      'recommended-support-workers',
      async () => {
        const { page, filter, includes, per_page, sort }: SupportWorkerFilter = options || {
          page: 1,
          per_page: 10,
          filter: {},
          includes: ['avatar'],
        };

        const supportWorker = new SupportWorker();
        supportWorker.limit(per_page).page(page);

        const supportWorkers = await supportWorker.custom('jobs/user-recommendations').get();
        return { data: supportWorkers, meta: supportWorker.meta };
      },
      { server: false }
    );
  }

  findSupportWorker(id: number) {
    return useAsyncData<ISupportWorker>(
      'support-worker',
      async () => {
        return (await new SupportWorker().find(id)) as ISupportWorker;
      },
      { server: false }
    );
  }
}

const service = new SupportWorkerService();
export default service;
