<script lang="ts" setup>
import { ComplateProfileDialog } from '@/components';
import noListingImg from '@/assets/img/no-listing-placeholder.svg';
import { AppTypography, AppFormCard, AppButton } from '@/components/base';
import { useAuthStore } from '@/modules/auth/store/auth';

const props = withDefaults(
  defineProps<{
    title?: string;
    subtitle?: string;
  }>(),
  {
    title: 'In need of a support worker?',
    subtitle: 'You need to complete your profile first before you can create a job listing.',
  }
);

const showVerification = ref(false);
const { isFullyVerified } = useAuthStore();

const create = (): void => {
  if (!isFullyVerified.value) {
    toggleVerification(true);
  }
};

const toggleVerification = (bool: boolean = null): void => {
  showVerification.value = bool === null ? !showVerification.value : bool;
};
</script>
<template>
  <AppFormCard :no-shadow="false" :no-gutter="true" class="no-listing-card">
    <div class="d-flex justify-space-between py-2">
      <div>
        <AppTypography type="headline-1" class="mb-1">{{ title }}</AppTypography>
        <AppTypography type="subheadline-2" class="mb-2">{{ subtitle }}</AppTypography>
        <AppButton size="small" variant="outlined" class="no-listing-card__create" @click="create">
          Create a job listing <v-icon size="20" icon="mdi-chevron-right"
        /></AppButton>
      </div>
      <v-img width="102" height="107" max-width="102" :src="noListingImg" />
    </div>
  </AppFormCard>

  <ComplateProfileDialog v-model="showVerification" />
</template>
<style lang="scss" scoped>
.no-listing-card {
  background: #ebfbd1;

  :deep(.v-btn) {
    min-height: 35px;

    .v-btn__content {
      font-weight: 400;
      text-transform: none;
    }
  }
}
</style>
