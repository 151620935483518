import JobListing from '@/models/JobListing';
import type { JobListing as IJobListing, JobListingFilter } from '@/types/JobListing';

class JobListingService {
  getJobListings(options?: JobListingFilter) {
    return useAsyncData<IJobListing[]>(
      'job-listings',
      async () => {
        const { page, filter, includes, per_page, sort }: JobListingFilter = options || {
          page: 1,
          per_page: 10,
          includes: ['author'],
          filter: {},
          sort: '-created_at',
        };

        const jobListing = new JobListing();
        jobListing.include(includes).limit(per_page).page(page).orderBy(sort);

        const jobListings = await jobListing.get();
        return { data: jobListings, meta: jobListing.meta };
      },
      { server: false }
    );
  }

  getRecommendedJobListings(options?: JobListingFilter) {
    return useAsyncData<IJobListing[]>(
      'recommended-job-listings',
      async () => {
        const { page, filter, includes, per_page, sort }: JobListingFilter = options || {
          page: 1,
          per_page: 10,
          includes: ['author'],
          filter: {},
          sort: '-created_at',
        };

        const jobListing = new JobListing();
        jobListing.include(includes).limit(per_page).page(page).orderBy(sort);

        const jobListings = await jobListing.custom('jobs/recommended').get();
        return { data: jobListings, meta: jobListing.meta };
      },
      { server: false }
    );
  }

  findJobListing(id: number) {
    return useAsyncData<IJobListing>(
      'job-listing',
      async () => {
        return (await new JobListing().find(id)) as IJobListing;
      },
      { server: false }
    );
  }
}

const service = new JobListingService();
export default service;
