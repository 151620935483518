<script setup lang="ts">
import { Banner } from '@/components';
import { CompleteYourProfile } from '@/components/account';
import { JobList } from '@/components/support-seeker/lists';
import { SupportWorkerList } from '@/components/support-worker/lists';
import { loginCheck } from '@/core/services/LoginCheck';
import { useAuthStore } from '@/modules/auth/store/auth';
import { capitalize } from '@/utils/Helpers';
import { isEmpty } from 'lodash';
import { storeToRefs } from 'pinia';

useHead({
  title: 'Home',
});

definePageMeta({
  auth: true,
  alias: '/home',
});

const { getSession } = useUserAuth();
await getSession();

const { getUser, hasLoggedInUser, isClient, isWorker } = storeToRefs(useAuthStore());
onMounted(async () => {
  if (!hasLoggedInUser.value || !getUser.value?.email_verified) {
    await navigateTo({ name: 'login' });
  } else {
    const { setOptions } = useAuthStore();
    const { options } = storeToRefs(useAuthStore());
    if (!options.value.skipOnboarding) {
      setOptions({ skipOnboarding: true });
      await loginCheck(isEmpty(getUser.value.first_name) && isEmpty(getUser.value.first_name));
    }
  }
});

const welcomeLabel = computed((): string => {
  return `Hi ${capitalize((getUser.value?.first_name || '').toLowerCase())}!`;
});
</script>
<template>
  <v-container class="container">
    <Banner :title="welcomeLabel" class="mb-6" />
    <CompleteYourProfile class="mb-6" />

    <template v-if="isClient">
      <SupportWorkerList
        :recommended="true"
        title="Recommended Support Workers"
        subtitle="Based on your profile and availabilities"
      />
    </template>
    <template v-if="isWorker">
      <JobList
        :carousel="true"
        :recommended="true"
        title="Recommended job listings"
        subtitle="10 jobs listings matched your preference"
        parent-class="mb-6"
      />
      <JobList :carousel="true" title="Recent job listings" subtitle="10 job listing were recently posted" />
    </template>
  </v-container>
</template>
<style lang="scss" scoped>
.container {
  &__banner {
  }
}
</style>
