import supportWorkerService from '@/services/SupportWorkerService';
import type PaginatedListMeta from '@/types/PaginatedListMeta';
import type SupportWorker from '@/types/SupportWorker';
import { API_LIST_META } from '@/utils/fixtures/ApiListMeta';

export default function useGetServices() {
  const supportWorker = ref<SupportWorker | null>();
  const supportWorkers = ref<SupportWorker[]>([]);
  const supportWorkersMeta = ref<PaginatedListMeta>(API_LIST_META);
  const fetching = ref(false);

  const getSupportWorkers = async (
    page: number = 1,
    limit: number = 10,
    recommended?: boolean = false
  ): SupportWorker[] => {
    if (fetching.value) return;

    if (page === 1) {
      supportWorkers.value = [];
    }
    fetching.value = true;
    supportWorkersMeta.value = API_LIST_META;

    const payload = {
      page: page,
      per_page: limit,
    };

    const { data } = recommended
      ? await supportWorkerService.getRecommendedSupportWorkers(payload)
      : await supportWorkerService.getSupportWorkers(payload);

    const newItems = data.value.data || [];
    if (page === 1) {
      supportWorkers.value = newItems;
    } else {
      supportWorkers.value.push(...newItems);
    }
    supportWorkersMeta.value = data.value.meta || API_LIST_META;
    fetching.value = false;

    return supportWorkers.value;
  };

  const findSupportWorker = async (id: number): SupportWorker | null => {
    if (fetching.value) return;

    supportWorker.value = null;
    fetching.value = true;

    const { data } = await supportWorkerService.findSupportWorker(id);

    fetching.value = false;
    supportWorker.value = data.value || null;

    return supportWorker.value;
  };

  return {
    fetching,

    supportWorker,
    supportWorkers,
    supportWorkersMeta,

    getSupportWorkers,
    findSupportWorker,
  };
}
